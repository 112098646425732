.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  font-family: 'Outfit', sans-serif;
  font-size: 20px;
  line-height: 1.4;
  cursor: pointer;
  color: #000;
  padding-left: 180px;
  padding-right: 20px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  background-size: 1000px 16px;
  background-position: left -840px top 6px;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-large-right-mint.svg);
  transition: all 0.4s;
}
.button:hover,
.button:focus {
  padding-left: 200px;
  padding-right: 0;
  background-position: left -820px top 6px;
}
@media (max-width: 1023px) {
  .button {
    font-size: 16px;
    line-height: 1.5;
    background-position: left -920px top 4px;
    padding-left: 100px;
  }
  .button:hover,
  .button:focus {
    padding-left: 120px;
    background-position: left -900px top 4px;
  }
}
.section--one .subcontent.subcontent--1 {
  width: calc(50% - 50px);
}
.section--one .subcontent.subcontent--2 {
  float: right;
  width: calc((50% - 50px) + var(--containerIndent));
}
.area--two {
  width: calc(100% + 100px);
  margin-left: -50px;
}
.area--two .unit {
  margin-left: 50px;
  margin-right: 50px;
  width: calc(100% - 100px);
}
.area--two .unitOne--1-2 {
  width: calc(50% - 100px);
}
.area--two .unitOne--1-2WithIndent {
  width: calc(50% - 100px);
}
.area--two .unitTwo .unit__background {
  width: calc(50% - 50px);
}
.area--two .unitTwo--imageLeft .unit__content {
  padding-left: calc(50% + 50px);
}
.area--two .unitTwo--imageRight .unit__content {
  padding-right: calc((50% + 50px) + var(--containerIndent));
}
.area--two .formOne--1-2 {
  width: calc(50% - 100px);
}
/*# sourceMappingURL=./screen-large.css.map */